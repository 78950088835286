// eslint-disable-next-line import/no-extraneous-dependencies
import ReactMarkdown from 'react-markdown';
// eslint-disable-next-line import/no-extraneous-dependencies
import rehypeRaw from 'rehype-raw';
import { twMerge } from 'tailwind-merge';

import styles from './MarkdownContent.module.css';
type MarkdownContentProps = {
  content: string;
  className?: string;
};
export const MarkdownContent = ({
  content,
  className = '',
}: MarkdownContentProps) => {
  return (
    <ReactMarkdown
      className={twMerge(styles.Main, className)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rehypePlugins={[rehypeRaw as any]}
    >
      {content}
    </ReactMarkdown>
  );
};
