'use client';

import classNames from 'classnames';
import React from 'react';

import Icon, { IconsNames } from '../Icon';

type ButtonProps = {
  color?: 'primary' | 'secondary';
  size?: 'small' | 'regular' | 'large';
  className?: string;
  icon?: IconsNames;
  href: string;
  onClick?: () => void;
  children: React.ReactNode;
};

const LinkButton: React.FC<ButtonProps> = ({
  color = ['primary', 'secondary'],
  size = 'large',
  icon,
  children,
  className,
  href,
  onClick,
}) => {
  return (
    <a
      href={href}
      className={classNames(`btn w-full ${className || ''}`, {
        'btn-primary': color === 'primary',
        'btn-secondary': color === 'secondary',
        'btn-small': size === 'small',
        'btn-regular': size === 'regular',
        'btn-large': size === 'large',
      })}
      onClick={onClick}
    >
      <p className="flex font-medium">
        {icon && (
          <Icon
            name={icon}
            className="mr-8 w-24 min-w-[24px] h-24 fill-current"
          />
        )}
        <span className="whitespace-nowrap">{children}</span>
      </p>
    </a>
  );
};

export default LinkButton;
