'use client';

import dynamic from 'next/dynamic';
import React from 'react';

import { Header } from '@/app/components/sections/Header';
import LINKS from '@/constants/links';
import { headerData } from '@/resources/header';

import { DefaultLayoutProps } from '../Default';
import style from './Documentation.module.css';

const Footer = dynamic(() => import('../shared/Footer'));

const DocumentationLayout: React.FC<DefaultLayoutProps> = ({
  children,
  header = headerData,
}) => {
  return (
    <div className="flex flex-col max-w-[100vw] min-h-[100vh]">
      <div className="sticky top-0 z-[999] bg-white">
        <Header
          customQRCodeLink={LINKS.APP_LINK_APPSFLYER_QR_MENU}
          {...header}
        />
      </div>
      <div className={style.content}>{children}</div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default DocumentationLayout;
