import { FaqQuestionProps } from '@/types/cms';

export const setItemsFaqSeo = (items: FaqQuestionProps[]) => {
  const faqItems: { questionName: string; acceptedAnswerText: string }[] = [];

  items.map(({ answer, question }) => {
    return faqItems.push({
      questionName: answer,
      acceptedAnswerText: question,
    });
  });

  return faqItems;
};
