'use client';

import { useResponsiveQuery } from 'atomic-layout';
import { useCallback, useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import QRCode from '@/components/v3/QRCode';
import { AmplitudeContext } from '@/contexts/amplitude';
import { ButtonProps } from '@/types/cms';
import { handleAppsflyerUtmUrl } from '@/utils/handleAppsflyerUtmUrl';

import { Choose, For } from '../UtilitiesComponents';

interface ButtonBoxProps {
  qrCodeLink?: string;
  buttons?: ButtonProps[];
  className?: string;
  section: string;
  cta?: string;
  onClick?: () => void;
}

const ButtonBox: React.FC<ButtonBoxProps> = ({
  qrCodeLink,
  buttons,
  className,
  section,
  cta,
  onClick,
}) => {
  const isMobileAndTablet = useResponsiveQuery({ to: 'lg' });
  const [utmUrl, setUtmUrl] = useState(qrCodeLink);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const { analytics } = useContext(AmplitudeContext);

  const updateUtmUrl = useCallback(
    () => setUtmUrl(handleAppsflyerUtmUrl(qrCodeLink)),
    [qrCodeLink],
  );

  useEffect(updateUtmUrl);

  const handleClickButton = (section: string, label: string) => {
    !isMobileAndTablet && setToggleDrawer(!toggleDrawer);
    analytics?.track({
      event_type: 'cta stone',
      event_properties: {
        name: 'cta stone',
        description: 'Evento disparado quando o usuário clicar nos ctas da LP',
        section_reference: section,
        cta_reference: cta ?? label,
      },
    });
  };

  return (
    <For
      of={buttons || []}
      render={({ color, size, label, link }, index) => {
        return (
          <Choose key={index}>
            <Choose.When condition={Boolean(link)}>
              <Button
                key={label}
                href={link?.href}
                hash={link?.hash}
                size={size}
                color={color}
                query={link?.query}
                target={link?.target}
                className={className}
                aria-label={label}
                onClick={() => {
                  if (onClick) {
                    onClick;
                  }
                  handleClickButton(section, label);
                }}
              >
                {label}
              </Button>
            </Choose.When>
            <Choose.Otherwise>
              <QRCode
                qrCodeLink={qrCodeLink}
                isShowing={toggleDrawer}
                onToggle={() => setToggleDrawer(!toggleDrawer)}
              />
              <Choose>
                <Choose.When condition={isMobileAndTablet}>
                  <Button
                    href={utmUrl}
                    color={color}
                    size={size}
                    onClick={() => handleClickButton(section, label)}
                    className={className}
                  >
                    {label}
                  </Button>
                </Choose.When>
                <Choose.Otherwise>
                  <Button
                    color={color}
                    onClick={() => handleClickButton(section, label)}
                    className={className}
                  >
                    {label}
                  </Button>
                </Choose.Otherwise>
              </Choose>
            </Choose.Otherwise>
          </Choose>
        );
      }}
    />
  );
};

export default ButtonBox;
