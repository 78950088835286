'use client';

import React, { useEffect, useMemo } from 'react';

import { HeaderProps } from '@/app/components/sections/Header/types';
import { isProduction } from '@/constants/checkEnvironment';
import DefaultLayout from '@/layouts/Default';
import DocumentationLayout from '@/layouts/Documentation';
import FormsPage from '@/layouts/FormsPage';
import LayoutWithoutNavigation from '@/layouts/LayoutWithoutNavigation';
import WithoutHeader from '@/layouts/WithoutHeader';
import WithoutHeaderAndFooter from '@/layouts/WithoutHeaderAndFooter';
import { GApageView } from '@/utils/Analytics';

type LayoutNames =
  | 'default'
  | 'documentation'
  | 'withoutHeader'
  | 'withoutHeaderAndFooter'
  | 'formsPage'
  | 'withoutNavigation';

interface ILayout {
  name: LayoutNames;
  LayoutComponent: React.FC<{
    headerStickyOnMobile?: boolean;
    children?: React.ReactNode;
    header?: HeaderProps;
  }>;
}

const Layout: React.FC<{
  children?: React.ReactNode;
  layoutName?: LayoutNames;
  header?: HeaderProps;
  headerStickyOnMobile?: boolean;
}> = ({ children, layoutName = 'default', headerStickyOnMobile, header }) => {
  useEffect(() => {
    if (isProduction) {
      GApageView(window.location.pathname);
    }
  }, []);

  const Layouts: ILayout[] = useMemo(() => {
    return [
      { name: 'default', LayoutComponent: DefaultLayout },
      { name: 'documentation', LayoutComponent: DocumentationLayout },
      { name: 'withoutHeader', LayoutComponent: WithoutHeader },
      {
        name: 'withoutHeaderAndFooter',
        LayoutComponent: WithoutHeaderAndFooter,
      },
      { name: 'formsPage', LayoutComponent: FormsPage },
      { name: 'withoutNavigation', LayoutComponent: LayoutWithoutNavigation },
    ];
  }, []);

  const ActiveLayout = useMemo(() => {
    return Layouts.find(layout => layout.name === layoutName);
  }, [layoutName, Layouts]);
  return (
    <>
      {ActiveLayout ? (
        <ActiveLayout.LayoutComponent
          headerStickyOnMobile={headerStickyOnMobile}
          header={header}
        >
          {children}
        </ActiveLayout.LayoutComponent>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
export default Layout;
