import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';

const Footer = dynamic(
  () =>
    /* webpackChunkName: "Shared_Footer" */
    import('../shared/Footer'),
);

const WithoutHeader: React.FC<{
  children?: React.ReactNode;
  disableHiddenBodyOverflowX?: boolean;
}> = ({ children, disableHiddenBodyOverflowX }) => {
  return (
    <div
      className={classNames({
        'overflow-x-hidden max-w-[100vw]': !disableHiddenBodyOverflowX,
      })}
    >
      {children}
      <Footer />
    </div>
  );
};

export default WithoutHeader;
