import classNames from 'classnames';
import React from 'react';

const WithoutHeader: React.FC<{
  children?: React.ReactNode;
  disableHiddenBodyOverflowX?: boolean;
}> = ({ children, disableHiddenBodyOverflowX }) => (
  <div
    className={classNames({
      'overflow-x-hidden max-w-[100vw]': !disableHiddenBodyOverflowX,
    })}
  >
    {children}
  </div>
);

export default WithoutHeader;
