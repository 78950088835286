'use client';

import { useResponsiveQuery } from 'atomic-layout';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React from 'react';

import { Header } from '@/app/components/sections/Header';
import { HeaderProps } from '@/app/components/sections/Header/types';
import { headerData } from '@/resources/header';
import classNames from 'classnames';

const Footer = dynamic(() => import('../shared/Footer'));

export interface DefaultLayoutProps {
  children?: React.ReactNode;
  header?: HeaderProps;
  headerStickyOnMobile?: boolean;
  disableHiddenBodyOverflowX?: boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  header = headerData,
  headerStickyOnMobile,
  disableHiddenBodyOverflowX,
}) => {
  const pathname = usePathname();
  const isMobile = useResponsiveQuery({ to: 'sm' });

  const pagesToCheck = ['/maquina-de-cartao/', '/planos/', '/'];
  const pagesToRemoveButton =
    pagesToCheck.some(page => pathname === page) && isMobile;

  const isButtonVisible = !pagesToRemoveButton && header.isButtonMobile;
  const isCustomerSupportVisible =
    !pagesToRemoveButton && header.isCustomerSupportMobile;

  return (
    <div
      className={classNames({
        'overflow-x-hidden max-w-[100vw]': !disableHiddenBodyOverflowX,
      })}
    >
      <Header
        headerStickyOnMobile={headerStickyOnMobile}
        {...header}
        isButtonMobile={isButtonVisible}
        isCustomerSupportMobile={isCustomerSupportVisible}
      />
      {children}
      <Footer />
    </div>
  );
};
export default DefaultLayout;
