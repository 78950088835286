'use client';

import React from 'react';

import { Header } from '@/app/components/sections/Header';
import { HeaderProps } from '@/app/components/sections/Header/types';

import ContentFooterWithoutRoutes from '../shared/ContentFooterWithoutRoutes';

const LayoutWithoutNavigation: React.FC<{
  showDrawer?: boolean;
  headerStickyOnMobile?: boolean;
  children?: React.ReactNode;
  header?: HeaderProps;
}> = ({ children, headerStickyOnMobile, header }) => {
  return (
    <div className="max-w-[100vw] overflow-x-clip">
      <Header headerStickyOnMobile={headerStickyOnMobile} {...header} />
      {children}
      <ContentFooterWithoutRoutes />
    </div>
  );
};

export default LayoutWithoutNavigation;
