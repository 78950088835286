import React from 'react';

import { Header } from '@/app/components/sections/Header';
import classNames from 'classnames';

const FormsPage: React.FC<{
  children?: React.ReactNode;
  headerStickyOnMobile?: boolean;
  disableHiddenBodyOverflowX?: boolean;
}> = ({ children, headerStickyOnMobile, disableHiddenBodyOverflowX }) => {
  return (
    <div
      className={classNames({
        'overflow-x-hidden max-w-[100vw]': !disableHiddenBodyOverflowX,
      })}
    >
      <Header headerStickyOnMobile={headerStickyOnMobile} />
      {children}
    </div>
  );
};

export default FormsPage;
