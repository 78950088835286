/* eslint-disable tailwindcss/no-contradicting-classname */

'use client';

import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import Button from '@/components/Button';
import LinkButton from '@/components/v3/LinkButton';
import { MarkdownContent } from '@/components/v4/MarkdownContent';
import { AmplitudeContext } from '@/contexts/amplitude';
import { FaqProps as FaqCMSProps } from '@/types/cms';

import FaqItem from './components/FaqItem';

export interface FaqProps extends Omit<FaqCMSProps, 'id' | 'buttons'> {
  btnToTop?: boolean;
  buttonIsShowMobileAndTablet?: boolean;
  children: ReactNode;
  analyticsSectionReference?: string;
}

const Faq: React.FC<FaqProps> = ({
  referId,
  title,
  description,
  questions,
  children,
  analyticsSectionReference,
  btnToTop = false,
  buttonIsShowMobileAndTablet = true,
}) => {
  const maxFaqItems = 5;
  const [showAllFaqItems, setShowAllFaqItems] = useState(!maxFaqItems);

  const container = useRef<HTMLDivElement>(null);

  const { analytics } = useContext(AmplitudeContext);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const handleClickButtonFaq = () => {
    const containerRef = container.current;
    if (containerRef) {
      if (showAllFaqItems) {
        const containerOffsetTop = containerRef?.offsetTop || 0;
        window.scrollTo({
          top: containerOffsetTop,
          behavior: 'smooth',
        });
      } else {
        const containerOffsetTop =
          containerRef?.offsetTop + containerRef?.offsetHeight / 2 || 0;
        window.scrollTo({
          top: containerOffsetTop,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if (inView) {
      analytics?.track({
        event_type: 'section viewed',
        event_properties: {
          name: 'section viewed',
          description:
            'Evento disparado quando o usuário visualiza uma sessão especifica da pagina',
          section_reference: analyticsSectionReference,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, inView]);

  return (
    <section
      id={referId}
      className=" bg-display-100"
      ref={ref}
      data-testid="faq-section"
    >
      <div
        ref={container}
        className="flex flex-col lg:flex-row gap-24 lg:gap-40 justify-center lg:items-start py-40 lg:py-[120px] px-24 mx-auto max-w-[1280px]"
      >
        <div className="flex flex-col gap-16 lg:gap-24 justify-center items-start max-w-full lg:max-w-[435px] xl:max-w-[455px]">
          <h2 className="items-start font-display text-[32px] lg:text-[40px] leading-[38px] lg:leading-[48px] heading-2">
            {title}
          </h2>
          {description && (
            <MarkdownContent
              content={description}
              className="items-start paragraph-18 lg:paragraph-20"
            />
          )}
          <div
            className={`${
              buttonIsShowMobileAndTablet
                ? 'flex flex-col md:flex-row'
                : ' hidden lg:flex flex-col lg:flex-row'
            } gap-16 justify-center items-start w-full md:w-max`}
          >
            {children}
          </div>
        </div>
        <div className="flex flex-col gap-24">
          <ul>
            {questions.map(({ question, answer }, index) => {
              return (
                <FaqItem
                  key={index}
                  question={question}
                  answer={answer}
                  currentItemPosition={index + 1}
                  itemsLength={questions.length}
                  show={
                    maxFaqItems && !showAllFaqItems
                      ? index <= maxFaqItems
                      : true
                  }
                />
              );
            })}
          </ul>
          <div className="flex flex-col md:flex-row gap-16 justify-center md:justify-between items-center md:items-stretch w-full">
            {questions.length > 6 && (
              <Button
                data-testid="faq-button"
                color="secondary"
                className="md:w-auto"
                onClick={() => {
                  setShowAllFaqItems(!showAllFaqItems);
                  handleClickButtonFaq();
                }}
              >
                {!showAllFaqItems
                  ? 'Ver mais perguntas'
                  : 'Ver menos perguntas'}
              </Button>
            )}
            {btnToTop && (
              <LinkButton
                className="w-full md:w-max btn-large"
                color="secondary"
                href="#"
                icon="arrow-up"
              >
                Retornar ao topo da página
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
